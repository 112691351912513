<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-card>
    <Overplay v-if="isShow" />
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="12">
          <b-form-group label="*Product name:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="Product name"
              rules="required"
            >
              <b-form-input
                v-model="value.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Product name"
                @input="generateSlug(value.name)"
              />
              <span class="count-input-length">
                {{ value.name ? value.name.length : 0 }}/100
              </span>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Position:" label-cols-md="3">
            <b-form-input
              v-model="value.position"
              type="number"
              placeholder="Position"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="value.short_content && value.short_content.length > 0"
          md="12"
        >
          <b-form-group label="Short content:" label-cols-md="3">
            <b-form-textarea
              v-model="value.short_content[1].value"
              rows="6"
              max-rows="6"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="value.short_content && value.short_content.length > 0"
          md="12"
        >
          <b-form-group label="Attribute:" label-cols-md="3">
            <div class="bg-gray">
              <b-form-group label="Options" label-cols-md="3">
                <b-form class="repeater-form" @submit.prevent="repeateAgain()">
                  <div
                    v-for="(item, index) in value.short_content[0].value"
                    :id="String(index)"
                    :key="index"
                    class="mb-1"
                  >
                    <b-row>
                      <b-col md="4">
                        <b-form-input
                          v-model="value.short_content[0].value[index].title"
                          placeholder=""
                        />
                      </b-col>
                      <b-col md="7">
                        <b-form-input
                          v-model="value.short_content[0].value[index].content"
                          placeholder=""
                        />
                      </b-col>
                      <b-col md="1">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="removeItem(index)"
                        >
                          <feather-icon icon="Trash2Icon" class="mr-25" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-form>
              </b-form-group>

              <b-form-group label-cols-md="3" label="">
                <div
                  v-if="value.short_content[0].value.length < 5"
                  class="fade-browse"
                  @click="repeateAgain()"
                >
                  <feather-icon icon="PlusCircleIcon" class="mr-25" />
                  More attribute ({{ value.short_content[0].value.length }}/5)
                </div>
              </b-form-group>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="* SKU:" label-cols-md="3">
            <validation-provider
              #default="{ errors }"
              name="SKU"
              rules="required"
            >
              <b-form-input
                v-model="value.sku"
                :state="errors.length > 0 ? false : null"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="* Category:" label-cols-md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3" v-if="value.category_name !== null">
                <b>{{ value.category_name }}</b>
              </div>
              <b-link v-b-modal.modal-xl>
                Change category
              </b-link>
            </div>
            <small class="text-danger" v-if="categoryIdErrorText !== null">{{
              categoryIdErrorText
            }}</small>
            <popup-add-cate v-model="value" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Brand:" label-cols-md="3">
            <v-select
              v-if="trademarkOptions"
              v-model="value.brand_id"
              label="name"
              :reduce="x => x.id"
              :options="trademarkOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Country of origin:" label-cols-md="3">
            <v-select
              v-if="originOptions"
              v-model="value.origin_id"
              label="name"
              :reduce="x => x.id"
              :options="originOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Supplier:" label-cols-md="3">
            <v-select
              v-if="supplierOptions"
              v-model="value.supplier_id"
              label="name"
              :reduce="x => x.id"
              :options="supplierOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="* Product description:" label-cols-md="3">
            <quill-editor
              ref="qEdit"
              :content="value.content"
              :options="snowOption"
              style="min-height: 180px;"
              @change="onEditorChange($event)"
            />
            <input id="getFile" type="file" @change="uploadFunction($event)" />
            <span class="count-textarea-length">
              {{ product_description_length }}/1000
            </span>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable operator-linebreak */
import { ValidationProvider } from 'vee-validate'
import {
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BLink,
  BButton,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Overplay from '@core/layouts/components/Loading.vue'
import PopupAddCate from './components/PopupAddCate.vue'

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BCard,
    BCardHeader,
    BCardBody,
    quillEditor,
    BLink,
    PopupAddCate,
    BButton,
    BForm,
    Overplay,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    categoryIdErrorText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShow: false,
      res: {
        trademark: { status: 0 },
        category: { status: 0 },
        origin: { status: 0 },
        supplier: { status: 0 },
      },
      required,
      conditionOptions: [
        { title: 'New' },
        { title: 'Open box' },
        { title: 'Certified refurbished' },
        { title: 'Delete' },
      ],
      trademarkOptions: null,
      categoryOptions: null,
      originOptions: null,
      supplierOptions: null,
      snowOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['image'],
            ],
            handlers: {
              image: () => {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      product_description_length: 0,
    }
  },
  async mounted() {
    this.isShow = true
    await this.loadTrademarkList()
    await this.loadCategoryList()
    await this.loadOriginList()
    await this.loadSupplierList()
    if (
      this.res.trademark.status === 200 &&
      this.res.category.status === 200 &&
      this.res.origin.status === 200 &&
      this.res.supplier.status === 200
    ) {
      this.isShow = false
    }
  },
  methods: {
    generateSlug(name) {
      this.value.slug = this.convertToSlug(name)
    },
    onEditorChange({ html }) {
      this.value.content = html
      this.product_description_length = html.length
    },
    async loadTrademarkList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        this.res.trademark = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_trademarks?template_id=${templateId}&limit=1000`,
        )
        if (this.res.trademark.status === 200) {
          if (this.res.trademark.data.status) {
            this.trademarkOptions = this.res.trademark.data.data.items
            this.trademarkOptions.push(
              {
                avatar: '',
                created_at: '',
                id: 'eyJpdiI6IkUyblZXbzZobDMyRW9tdmxWR0JDc0E9PSIsInZhbHVlIjoiSkNvQTNncElEN3JxSjhPN3l4aldIQT09IiwibWFjIjoiZTI2ZTVjNGNlZjVlOTA4NTdlYTVjNTg1N2EzMWY0MGZjNWNiYjg0YzZkNmQwNGI3NjU4M2YzMGQ3Yzk2NjRjMiJ9',
                is_active: 1,
                link: '#',
                name: '-- Chose trademark --',
                position: 1,
                updated_at: '',
              },
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(
                  this.showError(this.res.trademark.data.error, ','),
                ),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadCategoryList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        this.res.category = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_categories?template_id=${templateId}&limit=1000`,
        )
        if (this.res.category.status === 200) {
          if (this.res.category.data.status) {
            this.categoryOptions = this.res.category.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(this.res.category.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadOriginList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        this.res.origin = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_origins?template_id=${templateId}&limit=1000`,
        )
        if (this.res.origin.status === 200) {
          if (this.res.origin.data.status) {
            this.originOptions = this.res.origin.data.data.items
            this.originOptions.push(
              {
                id: 'eyJpdiI6IkUyblZXbzZobDMyRW9tdmxWR0JDc0E9PSIsInZhbHVlIjoiSkNvQTNncElEN3JxSjhPN3l4aldIQT09IiwibWFjIjoiZTI2ZTVjNGNlZjVlOTA4NTdlYTVjNTg1N2EzMWY0MGZjNWNiYjg0YzZkNmQwNGI3NjU4M2YzMGQ3Yzk2NjRjMiJ9',
                name: '-- Chose origin --',
                short_content: null,
                position: 1,
                is_active: 1,
                created_at: '',
              },
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(this.res.origin.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadSupplierList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        this.res.supplier = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_suppliers?template_id=${templateId}&limit=1000`,
        )
        if (this.res.supplier.status === 200) {
          if (this.res.supplier.data.status) {
            this.supplierOptions = this.res.supplier.data.data.items
            this.supplierOptions.push(
              {
                id: 'eyJpdiI6IkUyblZXbzZobDMyRW9tdmxWR0JDc0E9PSIsInZhbHVlIjoiSkNvQTNncElEN3JxSjhPN3l4aldIQT09IiwibWFjIjoiZTI2ZTVjNGNlZjVlOTA4NTdlYTVjNTg1N2EzMWY0MGZjNWNiYjg0YzZkNmQwNGI3NjU4M2YzMGQ3Yzk2NjRjMiJ9',
                name: '-- Chose supplier --',
                avatar: '',
                is_active: 1,
                address: '',
                phone: '',
                email: '',
                position: 2,
                created_at: '',
                updated_at: '',
              },
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(this.res.supplier.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeateAgain() {
      this.value.short_content[0].value.push({
        label: null,
        content: null,
      })
    },
    removeItem(index) {
      this.value.short_content[0].value.splice(index, 1)
    },
    uploadFunction(e) {
      const vm = this
      const image = e.target.files[0]
      if (image) {
        if (image.size > 5000000) {
          alert('Picture is too large! < 5MB')
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(image)
          reader.onload = ev => {
            vm.postImge(ev.target.result)
          }
        }
      }
    },
    async postImge(image) {
      const range = this.$refs.qEdit.quill.getSelection()
      const params = {
        template_id: `${JSON.parse(localStorage.getItem('templateID')).id_root}`,
        type: 'desktop',
        table: 'platform_products',
        table_media: 'platform_ckeditor_medias',
        avatar: image,
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_IMG_URL}/create_only_platform`,
        params,
      )
      this.$refs.qEdit.quill.insertEmbed(
        range.index,
        'image',
        res.data.data.image,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
#getFile {
  display: none;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
button svg {
  margin-right: 0 !important;
}
</style>
