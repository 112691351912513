<template>
  <b-card>
    <b-card-header><b class="h1">Media management</b></b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="3">
          <p><b>*Photos(0)</b></p>
          <p class="text-muted">
            Tips:
          </p>
          <ul>
            <li>- Use natural light and no flash.</li>
            <li>- Include a common object for scale.</li>
            <li>- Show the item being held, worn, or used.</li>
            <li>- Shoot against a clean, simple background.</li>
          </ul>
        </b-col>
        <b-col md="9">
          <!-- <div class="d-flex align-items-center">
            <p class="flex-grow-1">
              We recommend adding 3 more photos
            </p>
            <p @click="handleDeleteAll">Delete All</p>
          </div> -->
          <b-row class="no-gutters">
            <b-col
              md="3"
              class="fade-browse d-flex align-items-center justify-content-center review-img-avatar"
            >
              <div>
                <b-button
                  variant="danger"
                  class="review-img__del_avatar btn-icon rounded-circle"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-form-file
                  accept=".jpg, .png, .gif"
                  class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                  @change="handlerChangeAvatar"
                />
                <b-aspect style="width:100%;" aspect="1:1">
                  <b-img-lazy
                    v-if="value.avatar && value.avatar !== null"
                    :src="value.avatar"
                    fluid
                    thumbnail
                    rounded
                    class="w-100"
                    style="object-fit: contain;"
                  />
                </b-aspect>
              </div>
            </b-col>
            <b-col md="9">
              <div class="d-flex flex-wrap">
                <b-col
                  v-for="(item, index) in value.list_thumbnail_edit"
                  :key="index"
                  class="review-img mb-1"
                  md="3"
                >
                  <b-button
                    variant="danger"
                    class="review-img__del btn-icon rounded-circle"
                    @click.stop="delImg(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!-- <b-button
                    for="__BVID__505"
                    variant="danger"
                    class="review-img__del btn-icon rounded-circle"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button> -->
                  <!-- <b-form-file accept=".jpg, .png, .gif" class="img-box" /> -->
                  <b-aspect aspect="1:1">
                    <b-img-lazy
                      :src="item.full_link"
                      fluid
                      thumbnail
                      rounded
                      class="w-100"
                      style="object-fit: contain;"
                    />
                  </b-aspect>
                </b-col>
              </div>
            </b-col>
            <b-col md="12" class="pt-1">
              <div
                class="fade-browse text-center align-items-center justify-content-center w-100 p-2 rounded"
              >
                <p class="pt-1">
                  Add upto 12 photos so buyers can see every detail.
                </p>
                <div class="d-flex flex-wrap">
                  <b-col md="6">
                    <b-form-file
                      id="input-product"
                      multiple
                      accept=".jpg, .png, .gif"
                      class="img-box"
                      @change="previewFiles"
                    />
                    <label for="input-product" class="btn-add">
                      <feather-icon icon="UploadCloudIcon" />
                      Upload from your PC
                    </label>
                  </b-col>
                  <b-col md="6">
                    <!-- <b-form-file
                      id="input-product"
                      multiple
                      accept=".jpg, .png, .gif"
                      class="img-box"
                      @change="previewFiles"
                    /> -->
                    <label class="btn-add">
                      <feather-icon icon="PlusCircleIcon" />
                      Add from Media center
                    </label>
                  </b-col>
                </div>
              </div>
              <p
                class="text-muted mt-1 text-center mx-auto"
                style="font-size: 12px;"
              >
                Add up to 12 photos. We don't allow photos with extra borders,
                text or artwork.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
    <!-- <PhotoVariation
      v-if="value.ware_house && value.ware_house.length > 0"
      v-model="value"
    /> -->
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BFormFile,
  BButton,
  BAspect,
  BImgLazy,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import PhotoVariation from '@/views/pages/product/product-edit/components/PhotoVariation.vue'
// import draggable from 'vuedraggable'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BFormFile,
    BButton,
    BImgLazy,
    BAspect,
    // draggable,
    // PhotoVariation,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    value(val) {
      this.value = val
    },
  },
  methods: {
    handleDeleteAll() {
      this.value.avatar = null
      this.value.list_thumbnail_edit = []
    },
    delImg(index) {
      this.value.list_thumbnail_edit.splice(index, 1)
    },
    handlerChangeAvatar(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.value.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    previewFiles(input) {
      let i = 0
      if (
        // eslint-disable-next-line operator-linebreak
        input.target.files.length + this.value.list_thumbnail_edit.length <=
        12
      ) {
        // eslint-disable-next-line no-plusplus
        for (i; i < input.target.files.length; i++) {
          const file = input.target.files[i]
          const reader = new FileReader()
          // eslint-disable-next-line no-loop-func
          if (i > 0) {
            reader.onload = e => {
              const item = {
                id: null,
                full_link: e.target.result,
                path: null,
                base64: e.target.result,
              }
              this.value.list_thumbnail_edit.push(item)
            }
          } else {
            reader.onload = e => {
              if (this.value.avatar !== null) {
                const item = {
                  id: null,
                  full_link: e.target.result,
                  path: null,
                  base64: e.target.result,
                }
                this.value.list_thumbnail_edit.push(item)
              } else {
                this.value.avatar = e.target.result
              }
            }
          }
          reader.onerror = error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
          reader.readAsDataURL(file)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Maximum is 12 picture'),
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review-img,
.review-img-avatar {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(100, 100, 100, 0.75);
    left: 1rem;
    right: 1rem;
    bottom: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  &__del {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before,
    .review-img__del,
    .review-img__del_avatar {
      opacity: 1;
    }
  }
}
.review-img-avatar {
  &::before {
    content: '';
    width: 100%;
    left: 0;
  }
  .review-img__del_avatar {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    z-index: 1;
    width: 100%;
    min-height: 3rem;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .img-box {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 1rem;
    right: 1rem;
    width: 0;
  }
}
</style>
