<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-row>
    <Overplay v-if="model.isShow" />
    <b-col md="12">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-card>
            <!-- <b-card-header><b class="h1">Basic information</b></b-card-header> -->
            <b-card-body>
              <b-row>
                <b-col md="12">
                  <b-form-group label="Display on/off:" label-cols-md="3">
                    <b-form-checkbox
                      v-model="model.is_active"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Warehouse:" label-cols-md="3">
                    <b-form-checkbox
                      v-model="model.is_warehouse"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <BasicInformation
            id="BI"
            v-model="model"
            :categoryIdErrorText="categoryIdErrorText"
          />
          <SalesInformation id="SI" v-model="model" />
          <MediaManagement id="MM" v-model="model" />
          <!-- <ShippingDetail id="SD" v-model="model" /> -->
          <SEOInfo id="SE" v-model="model" />
          <!-- submit button -->
          <b-card>
            <b-card-body class="text-right">
              <div class="w-75 ml-auto" style="font-size: 12px;">
                <p class="text-muted">
                  If your item sells, we charge a final value fee based on a
                  percentage of the total cost to the buyer, less any sales tax.
                </p>
                <p class="text-muted">
                  By clicking the List item button, you agree to pay fees above,
                  accept the listing conditions above, and assume full
                  responsibility for the content of the listing and item
                  offered.
                </p>
              </div>
              <div class="d-flex align-items-center justify-content-end mt-2">
                <b-button variant="outline-secondary" type="button">
                  Save and Delist
                </b-button>
                <b-button
                  variant="primary"
                  type="submit"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </validation-observer>
    </b-col>
    <!-- <b-col sm="12" md="3" class="position-relative h-100">
      <div class="position-fixed right-fixed-menu">
        <b-card class="m-0">
          <b-card-body>
            <scrollactive
              active-class="active"
              :offset="150"
              :duration="400"
              bezier-easing-value=".5,0,.35,1"
            >
              <b-link href="#BI" class="scrollactive-item d-block">
                Basic information
              </b-link>
              <b-link href="#SI" class="scrollactive-item d-block">
                Sales information
              </b-link>
              <b-link href="#MM" class="scrollactive-item d-block">
                Media management
              </b-link>
              <b-link href="#SD" class="scrollactive-item d-block">
                Shipping detail
              </b-link>
              <b-link href="#SE" class="scrollactive-item d-block">
                SEO Optimization
              </b-link>
            </scrollactive>
          </b-card-body>
        </b-card>
      </div>
    </b-col> -->
  </b-row>
</template>
<script>
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
import VueScrollactive from 'vue-scrollactive'
import {
  BRow,
  BCol,
  // BLink,
  BForm,
  BButton,
  BCard,
  BCardBody,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import BasicInformation from '@/views/pages/product/product-edit/BasicInformation.vue'
import SalesInformation from '@/views/pages/product/product-edit/SalesInformation.vue'
import MediaManagement from '@/views/pages/product/product-edit/MediaManagement.vue'
// import ShippingDetail from '@/views/pages/product/product-edit/ShippingDetail.vue'
import SEOInfo from '@/views/pages/product/product-edit/SEOInfo.vue'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Overplay from '@core/layouts/components/Loading.vue'

Vue.use(VueScrollactive)

const isBase64 = require('is-base64')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    // BLink,
    BasicInformation,
    SalesInformation,
    MediaManagement,
    // ShippingDetail,
    SEOInfo,
    BButton,
    ValidationObserver,
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    Overplay,
  },
  mixins: [general],
  data() {
    return {
      categoryIdErrorText: null,
      model: {
        isShow: true,
      },
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        this.model.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_product/${this.$route.params.id}?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const item = res.data.data
            this.model = {
              id: item.id,
              // eslint-disable-next-line no-unneeded-ternary
              is_active: item.is_active === 1 ? true : false,
              sku: item.sku,
              // eslint-disable-next-line no-unneeded-ternary
              is_warehouse: item.is_warehouse === 1 ? true : false,
              name: item.name,
              short_content:
                item.short_content !== null
                  ? JSON.parse(item.short_content)
                  : null,
              category_id: item.platform_category_id,
              category_name: null,
              brand_id: item.trademark_id,
              origin_id: item.origin_id,
              supplier_id: item.supplier_id,
              content: item.content,
              price: item.price,
              avatar: item.avatar,
              list_thumbnail_edit: [],
              list_thumbnail: item.list_thumbnail,
              list_thumbnail_id:
                item.list_thumbnail_id !== null
                  ? item.list_thumbnail_id.split(',')
                  : null,
              list_thumbnail_path: JSON.parse(item.list_thumbnail_path),
              title_page: item.title_page,
              meta_keyword: JSON.parse(item.meta_keyword),
              meta_description: item.meta_description,
              slug: item.slug,
              tags: item.tags,
              translation: item.translation,
              parent_warehouse: item.parent_warehouse,
              position: item.position,
              created_at: item.created_at,
              ware_house: [],
              ware_house_old: [],
              variationTable: {
                colName: [
                  {
                    key: 'variation_1',
                    value: '',
                    model: null,
                  },
                  {
                    key: 'variation_2',
                    value: '',
                    model: null,
                  },
                  {
                    key: 'price',
                    value: 'Price',
                  },
                  {
                    key: 'quantity',
                    value: 'Quantity',
                  },
                  {
                    key: 'sku',
                    value: 'SKU',
                  },
                  {
                    key: 'active',
                    value: 'Active',
                  },
                ],
                colVal: {
                  variation_1: [],
                  variation_2: [],
                },
                wareHouse: [],
              },
            }

            const resOrigin = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_origin/${item.origin_id}?template_id=${templateId}&limit=1000`,
            )
            if (resOrigin.status === 200) {
              if (resOrigin.data.status) {
                this.model.origin_id = resOrigin.data.data
              } else {
                this.model.origin_id = {
                  id: 'eyJpdiI6IkUyblZXbzZobDMyRW9tdmxWR0JDc0E9PSIsInZhbHVlIjoiSkNvQTNncElEN3JxSjhPN3l4aldIQT09IiwibWFjIjoiZTI2ZTVjNGNlZjVlOTA4NTdlYTVjNTg1N2EzMWY0MGZjNWNiYjg0YzZkNmQwNGI3NjU4M2YzMGQ3Yzk2NjRjMiJ9',
                  name: '-- Chose origin --',
                  short_content: null,
                  position: 1,
                  is_active: 1,
                  created_at: '',
                }
              }
            }
            const resTrademake = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_trademark/${item.trademark_id}?template_id=${templateId}&limit=1000`,
            )
            if (resTrademake.status === 200) {
              if (resTrademake.data.status) {
                this.model.brand_id = resTrademake.data.data
              } else {
                this.model.brand_id = {
                  avatar: '',
                  created_at: '',
                  id: 'eyJpdiI6IkUyblZXbzZobDMyRW9tdmxWR0JDc0E9PSIsInZhbHVlIjoiSkNvQTNncElEN3JxSjhPN3l4aldIQT09IiwibWFjIjoiZTI2ZTVjNGNlZjVlOTA4NTdlYTVjNTg1N2EzMWY0MGZjNWNiYjg0YzZkNmQwNGI3NjU4M2YzMGQ3Yzk2NjRjMiJ9',
                  is_active: 1,
                  link: '#',
                  name: '-- Chose trademark --',
                  position: 1,
                  updated_at: '',
                }
              }
            }
            const resSupplier = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_supplier/${item.supplier_id}?template_id=${templateId}&limit=1000`,
            )
            if (resSupplier.status === 200) {
              if (resSupplier.data.status) {
                this.model.supplier_id = resSupplier.data.data
              } else {
                this.model.supplier_id = {
                  id: 'eyJpdiI6IkUyblZXbzZobDMyRW9tdmxWR0JDc0E9PSIsInZhbHVlIjoiSkNvQTNncElEN3JxSjhPN3l4aldIQT09IiwibWFjIjoiZTI2ZTVjNGNlZjVlOTA4NTdlYTVjNTg1N2EzMWY0MGZjNWNiYjg0YzZkNmQwNGI3NjU4M2YzMGQ3Yzk2NjRjMiJ9',
                  name: '-- Chose supplier --',
                  avatar: '',
                  is_active: 1,
                  address: '',
                  phone: '',
                  email: '',
                  position: 2,
                  created_at: '',
                  updated_at: '',
                }
              }
            }
            if (
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              this.model.list_thumbnail.map(async (x, idx) => {
                const i = {
                  id: this.model.list_thumbnail_id[idx],
                  full_link: x,
                  path: this.model.list_thumbnail_path[idx],
                }
                this.model.list_thumbnail_edit.push(i)
              })
            }
            if (
              res.status === 200 &&
              resOrigin.status === 200 &&
              resTrademake.status === 200 &&
              resSupplier.status === 200
            ) {
              // this.model.isShow = false
            }
          } else {
            this.model.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.model.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (this.model.category_id === null) {
          this.categoryIdErrorText = 'The Category field is required'
        }
        if (success) {
          try {
            const params = {
              sku: this.model.sku,
              template_id: JSON.parse(localStorage.getItem('templateID')).id,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              position: Number(this.model.position),
              name: this.model.name,
              short_content:
                this.model.short_content !== null
                  ? JSON.stringify(this.model.short_content)
                  : null,
              platform_category_id: this.model.category_id,
              trademark_id:
                this.model.brand_id && this.model.brand_id.id
                  ? this.model.brand_id.id
                  : this.model.brand_id,
              origin_id:
                this.model.origin_id && this.model.origin_id.id
                  ? this.model.origin_id.id
                  : this.model.origin_id,
              supplier_id:
                this.model.supplier_id && this.model.supplier_id.id
                  ? this.model.supplier_id.id
                  : this.model.supplier_id,
              price: this.model.price,
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
              slug: this.model.slug,
              is_warehouse: Number(this.model.is_warehouse === true ? 1 : 0),
            }
            if (this.model.ware_house && this.model.ware_house.length > 0) {
              // let warehouseAddTemp = []
              // let warehouseDeleteTemp = []
              this.model.ware_house.map(item => {
                const x = {
                  first_attribute_id: item.first_attribute_id,
                  first_attribute_name: item.first_attribute_name,
                  first_attribute_name_type: item.first_attribute_name_type,
                  is_active: item.is_active,
                  is_warehouse: item.is_warehouse,
                  name_temp: item.name_temp,
                  price: item.price,
                  quantity: item.quantity,
                  second_attribute_id: item.second_attribute_id,
                  second_attribute_name: item.second_attribute_name,
                  second_attribute_name_type: item.second_attribute_name_type,
                  sku: item.sku,
                }
                if (isBase64(item.avatar, { allowMime: true })) {
                  x.avatar = item.avatar
                }
                // params.warehouse_add.push(JSON.stringify(x))
              })

              // Add new
              // warehouseAddTemp = this.model.ware_house.filter(
              //   this.comparer(this.model.ware_house_old, 'id'),
              // )
              // if (warehouseAddTemp.length > 0) {
              //   params.warehouse_add = []
              //   warehouseAddTemp.map(item => {
              //     params.warehouse_add.push(JSON.stringify(item))
              //   })
              // }

              // Delete old
              // warehouseDeleteTemp = this.model.ware_house_old.filter(
              //   this.comparer(this.model.ware_house, 'id'),
              // )

              // if (warehouseDeleteTemp.length > 0) {
              //   params.warehouse_delete = []
              //   warehouseDeleteTemp.map(item => {
              //     params.warehouse_delete.push(item.id)
              //   })
              // }
              // params.warehouse_update = []
              // this.model.ware_house
              //   .filter(
              //     this.comparer(
              //       warehouseAddTemp.concat(warehouseDeleteTemp),
              //       'id',
              //     ),
              //   )
              //   .map(item => {
              //     const i = {
              //       id: item.id,
              //       is_warehouse: item.is_warehouse,
              //       price: item.price,
              //       quantity: item.quantity,
              //       is_active: true,
              //       sku: item.sku,
              //     }
              //     if (isBase64(item.avatar, { allowMime: true })) {
              //       i.avatar = item.avatar
              //     }
              //     params.warehouse_update.push(JSON.stringify(i))
              //   })
            }
            if (isBase64(this.model.avatar, { allowMime: true })) {
              params.avatar = this.model.avatar
            }
            if (
              this.model.list_thumbnail_edit &&
              this.model.list_thumbnail_edit.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail_edit.map(async x => {
                if (isBase64(x.base64, { allowMime: true })) {
                  const thumbnail = {
                    template_id: JSON.parse(localStorage.getItem('templateID'))
                      .id,
                    table: 'platform_gallery',
                    table_media: 'platform_medias',
                    type: 'desktop',
                    avatar: x.base64,
                    table_field: 'list_thumbnail',
                  }
                  const resIMG = await Request.post(
                    this.$http,
                    `${process.env.VUE_APP_API_IMG_URL}/create_only_platform`,
                    thumbnail,
                  )
                  if (resIMG.status === 200) {
                    if (resIMG.data.status) {
                      const item = {
                        id: resIMG.data.data.id,
                        full_link: resIMG.data.data.image,
                        path: resIMG.data.data.path,
                      }
                      if (listThumbnailId) {
                        listThumbnailId += `,${item.id}`
                      } else {
                        listThumbnailId = item.id
                      }
                      listThumbnail.push(item.path)
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Login fail',
                          icon: 'AlertOctagonIcon',
                          variant: 'danger',
                          text: String(this.showError(resIMG.data.error, ',')),
                        },
                      })
                    }
                  }
                } else {
                  if (listThumbnailId) {
                    listThumbnailId += `,${x.id}`
                  } else {
                    listThumbnailId = x.id
                  }
                  listThumbnail.push(x.path)
                }
                index += 1
                if (index === this.model.list_thumbnail_edit.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handlerUpdate(params)
                }
              })
            } else {
              this.handlerUpdate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async handlerUpdate(params) {
      this.model.isShow = true
      const res = await Request.put(
        this.$http,
        `${process.env.VUE_APP_API_URL}/platform_product/${this.$route.params.id}`,
        params,
      )
      if (res.status === 200) {
        this.model.isShow = false
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Update Product success',
            },
          })
          this.$router.push('/product/list')
        } else {
          this.model.isShow = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login fail',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
</style>
